import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { FaGraduationCap, FaUniversity, FaSchool, FaLaptop } from 'react-icons/fa';

function EducationDetails() {
  const educationData = [
    {
      year: '2012 - 2016',
      title: 'B.C.SC',
      description: 'I studied at the University of Computer Studies and graduated in 2016 with a degree in Computer Science.',
      icon: <FaGraduationCap />,
      iconStyle: { background: 'rgb(33, 150, 243)', color: '#fff' },
    },
    {
      year: '2017',
      title: 'PHP Web Development',
      description: 'I\'ve completed the training course for PHP Web Development, and is awarded by Myanmar IT Consulting in 2017.',
      icon: <FaUniversity />,
      iconStyle: { background: 'rgb(233, 30, 99)', color: '#fff' },
    },
    {
      year: '2018',
      title: 'Web Application Development',
      description: 'Develop Blog System with register, login, user permission and API features. Develop custom web application, for UI/UX development experience. I\'ve completed for this course on MMS One Stop IT Solutions.',
      icon: <FaSchool />,
      iconStyle: { background: 'rgb(76, 175, 80)', color: '#fff' },
    },
    {
      year: '2020',
      title: 'Flutter & Dart - The Complete Guide [2024 Edition]',
      description: 'I\'ve completed for this course on Udemy. The instructors were highly knowledgeable and provided clear, detailed explanations.',
      icon: <FaLaptop />,
      iconStyle: { background: 'rgb(255, 87, 34)', color: '#fff' },
    },
    {
      year: '2022',
      title: 'The Complete Flutter Development Bootcamp with Dart',
      description: 'I\'ve completed for this course on Udemy. The course provided comprehensive training on Flutter and Dart, covering all essential concepts and techniques.',
      icon: <FaLaptop />,
      iconStyle: { background: 'rgb(128, 0, 128)', color: '#fff' },
    },
    {
      year: '2024',
      title: 'Native Kotlin Android',
      description: 'I\'ve completed for this course on AD-Coder. The course provided comprehensive training on Kotlin, covering all essential concepts and techniques.',
      icon: <FaLaptop />,
      iconStyle: { background: 'rgb(75, 0, 130)', color: '#fff' },
    },
  ];

  return (
    <div className="p-6 rounded-lg shadow-md mt-4 bg-white border-t-4 border-indigo-500">
      <h1 className="text-4xl font-bold mb-8 text-center">Educational Details</h1>
      <VerticalTimeline>
        {educationData.map((item, index) => (
          <VerticalTimelineElement
            key={index}
            date={item.year}
            iconStyle={item.iconStyle}
            icon={item.icon}
          >
            <h3 className="vertical-timeline-element-title text-xl font-bold">{item.title}</h3>
            <p>{item.description}</p>
          </VerticalTimelineElement>
        ))}
      </VerticalTimeline>
    </div>
  );
}

export default EducationDetails;
