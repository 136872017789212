import React, { useState } from "react";
import Sidebar from "./components/Sidebar";
import About from "./components/About";
import Experience from "./components/Experience";
import Project from "./components/Projects";
import Skills from "./components/Skills";
import Contact from "./components/Contact";
import EducationDetails from "./components/Education";

function App() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  return (
    <div className="flex min-h-screen">
      {/* Sidebar */}
      <div
        className={`fixed inset-y-0 left-0 z-50 transform lg:transform-none transition-transform duration-300 ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } lg:translate-x-0`}
      >
        <Sidebar closeSidebar={closeSidebar} />
      </div>

      {/* Content */}
      <div className="flex-1 p-4 md:p-6 lg:p-8 lg:ml-72">
        {" "}
        {/* Adjust margin-left */}
        <section id="about">
          <About />
        </section>
        <main>
          <section id="experience" className="py-8">
            <Experience />
          </section>
          <section id="skills" className="py-8">
            <Skills />
          </section>
          <section id="project" className="py-8">
            <Project />
          </section>
          <section id="education" className="py-8">
            <EducationDetails />
          </section>
          <section id="contact" className="py-8">
            <Contact />
          </section>
        </main>
      </div>

      {/* Mobile Menu Button */}
      <button
        className="fixed top-1 right-4 z-50 lg:hidden p-2 bg-custom-primary text-white rounded-md"
        onClick={toggleSidebar}
      >
        {isSidebarOpen ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4 6h16M4 12h16m-7 6h7"
            />
          </svg>
        )}
      </button>
    </div>
  );
}

export default App;
