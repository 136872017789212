import React from "react";
import { motion } from "framer-motion";
import { FaCheckCircle } from "react-icons/fa";

function Experience() {
  return (
    <section id="experience" className="bg-gray-100 py-12 mt-4 rounded-lg">
      <div className="max-w-6xl mx-auto px-6">
        <h2 className="text-4xl font-bold mb-8 text-center">Experience</h2>
        <div className="grid gap-8 grid-cols-1 md:grid-cols-2">
          <motion.div
            className="bg-white p-6 rounded-lg shadow-md border-t-4 border-indigo-500"
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.3 }}
          >
            <h3 className="text-xl font-semibold">Flutter Developer</h3>
            <p className="text-gray-600">UMG Myanmar</p>
            <p className="text-gray-500 mb-4">October 2023 - April 2024</p>
            <div className="flex items-center my-2">
              <FaCheckCircle
                size={20}
                className="mr-2 text-indigo-500 flex-shrink-0 text-center"
              />
              <p className="flex-grow">
                Developed and implemented user friendly mobile applications
                using the Flutter framework.
              </p>
            </div>
            <div className="flex items-center my-2">
              <FaCheckCircle
                size={20}
                className="mr-2 text-indigo-500 flex-shrink-0"
              />
              <p className="flex-grow">
                Collaborated with department head, backend developers and
                designers to create and integrate UI/UX design into
                applications.
              </p>
            </div>
            <div className="flex items-center my-2">
              <FaCheckCircle
                size={20}
                className="mr-2 text-indigo-500 flex-shrink-0"
              />
              <p className="flex-grow">
                Integrated third parties libraries, APIs and services to enhance
                app capabilities.
              </p>
            </div>
            <div className="flex items-center my-2">
              <FaCheckCircle
                size={20}
                className="mr-2 text-indigo-500 flex-shrink-0"
              />
              <p className="flex-grow">
                Employ BLoC architecture and state management for scalable
                codebases.
              </p>
            </div>
            <div className="flex items-center my-2">
              <FaCheckCircle
                size={20}
                className="mr-2 text-indigo-500 flex-shrink-0"
              />
              <p className="flex-grow">
                Employ Agile methodologies to manage projects and ensure timely
                delivery.
              </p>
            </div>
            <div className="flex items-center my-2">
              <FaCheckCircle
                size={20}
                className="mr-2 text-indigo-500 flex-shrink-0"
              />
              <p className="flex-grow">
                Writing well-designed, testable and efficient code.
              </p>
            </div>
            <div className="flex items-center my-2">
              <FaCheckCircle
                size={20}
                className="mr-2 text-indigo-500 flex-shrink-0"
              />
              <p className="flex-grow">
                Build re-useable components and create the documentation of
                components.
              </p>
            </div>
            <div className="flex items-center my-2">
              <FaCheckCircle
                size={20}
                className="mr-2 text-indigo-500 flex-shrink-0"
              />
              <p className="flex-grow">
                Publish mobile applications to the App Store and Google Play
              </p>
            </div>
          </motion.div>
          <motion.div
            className="bg-white p-6 rounded-lg shadow-md border-t-4 border-yellow-500"
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.3 }}
          >
            <h3 className="text-xl font-semibold">Application Developer</h3>
            <p className="text-gray-600">FreshMoe</p>
            <p className="text-gray-500 mb-4">
              September 2022 - September 2023
            </p>
            <div className="flex items-center my-2">
              <FaCheckCircle
                size={20}
                className="mr-2 text-yellow-500 flex-shrink-0"
              />
              <p className="flex-grow">
                Designed, implemented, tested Flutter and Dart code using
                Provider for IOS and Android.
              </p>
            </div>
            <div className="flex items-center my-2">
              <FaCheckCircle
                size={20}
                className="mr-2 text-yellow-500 flex-shrink-0"
              />
              <p className="flex-grow">
                Fixed bugs, upgraded existing capabilities, and added new
                features to the app.
              </p>
            </div>
            <div className="flex items-center my-2">
              <FaCheckCircle
                size={20}
                className="mr-2 text-yellow-500 flex-shrink-0"
              />
              <p className="flex-grow">
                Optimized existing code for better performance and reliability,
                reducing crash rates.
              </p>
            </div>
            <div className="flex items-center my-2">
              <FaCheckCircle
                size={20}
                className="mr-2 text-yellow-500 flex-shrink-0"
              />
              <p className="flex-grow">
                Collaborated with cross-functional teams to design and implement
                user interfaces for mobile applications.
              </p>
            </div>
            <div className="flex items-center my-2">
              <FaCheckCircle
                size={20}
                className="mr-2 text-yellow-500 flex-shrink-0"
              />
              <p className="flex-grow">
                Tested and debugged mobile applications across multiple devices
                and platforms, ensuring compatibility and functionality.
              </p>
            </div>
            <div className="flex items-center my-2">
              <FaCheckCircle
                size={20}
                className="mr-2 text-yellow-500 flex-shrink-0"
              />
              <p className="flex-grow">
                Added new features and functionalities to a fruits and
                vegetables e- commerce system, primarily using PHP (Laravel) and
                REST API for the backend process and Dart (Flutter) for the
                frontend process.
              </p>
            </div>
            <div className="flex items-center my-2">
              <FaCheckCircle
                size={20}
                className="mr-2 text-yellow-500 flex-shrink-0"
              />
              <p className="flex-grow">
                Demonstrated proficiency in PHP(Laravel), Dart(Flutter)
                algorithms, and problem-solving skills through successful
                project completion.
              </p>
            </div>
          </motion.div>
          <motion.div
            className="bg-white p-6 rounded-lg shadow-md border-t-4 border-green-500"
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.3 }}
          >
            <h3 className="text-xl font-semibold">Application Engineer</h3>
            <p className="text-gray-600">Teclink Myanmar</p>
            <p className="text-gray-500 mb-4">September 2020 - August 2022</p>
            <div className="flex items-center my-2">
              <FaCheckCircle
                size={20}
                className="mr-2 text-green-500 flex-shrink-0"
              />
              <p className="flex-grow">
                Implemented the user interface using Flutter, following SOLID
                principles and MVC/MVVM pattern for scalable and maintainable
                code.
              </p>
            </div>
            <div className="flex items-center my-2">
              <FaCheckCircle
                size={20}
                className="mr-2 text-green-500 flex-shrink-0"
              />
              <p className="flex-grow">
                Planned the clean architecture, including API integrations.
              </p>
            </div>
            <div className="flex items-center my-2">
              <FaCheckCircle
                size={20}
                className="mr-2 text-green-500 flex-shrink-0"
              />
              <p className="flex-grow">
                Handled all tasks related to mobile development, including bug
                fixing, testing and performance optimization to follow up SOLID
                principles.
              </p>
            </div>
            <div className="flex items-center my-2">
              <FaCheckCircle
                size={20}
                className="mr-2 text-green-500 flex-shrink-0"
              />
              <p className="flex-grow">
                Deployed the app to Play Store providing a smooth and successful
                release.
              </p>
            </div>
            <div className="flex items-center my-2">
              <FaCheckCircle
                size={20}
                className="mr-2 text-green-500 flex-shrink-0"
              />
              <p className="flex-grow">
                Improved the performance and reliability of the app through
                optimization techniques and code refactoring.
              </p>
            </div>
            <div className="flex items-center my-2">
              <FaCheckCircle
                size={20}
                className="mr-2 text-green-500 flex-shrink-0"
              />
              <p className="flex-grow">
                Add new features and functionalities for production management
                system mainly with PHP(Laravel), Dart(Flutter) and REST API.
                Developing of the mobile client(E-Commerce) online shop and
                delivery services project.
              </p>
            </div>
            <div className="flex items-center my-2">
              <FaCheckCircle
                size={20}
                className="mr-2 text-green-500 flex-shrink-0"
              />
              <p className="flex-grow">
                Collaborated on the development of a range of high-quality web
                applications, utilizing a variety of tech stacks including
                PHP(Laravel), JavaScript, Jquery, Git, Bootstrap, and MySQL.
              </p>
            </div>
            <div className="flex items-center my-2">
              <FaCheckCircle
                size={20}
                className="mr-2 text-green-500 flex-shrink-0"
              />
              <p className="flex-grow">Publish app to Play Store.</p>
            </div>
          </motion.div>
          <motion.div
            className="bg-white p-6 rounded-lg shadow-md border-t-4 border-blue-500"
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.3 }}
          >
            <h3 className="text-xl font-semibold">Web Developer</h3>
            <p className="text-gray-600">Vretailer Pte Ltd</p>
            <p className="text-gray-500 mb-4">March 2019 - May 2020</p>
            <div className="flex items-center my-2">
              <FaCheckCircle
                size={20}
                className="mr-2 text-blue-500 flex-shrink-0"
              />
              <p className="flex-grow">
                Responsible for writing server-side web application logic.
              </p>
            </div>
            <div className="flex items-center my-2">
              <FaCheckCircle
                size={20}
                className="mr-2 text-blue-500 flex-shrink-0"
              />
              <p className="flex-grow">
                Develop back-end components using PHP(Laravel) and also design
                database in project.
              </p>
            </div>
            <div className="flex items-center my-2">
              <FaCheckCircle
                size={20}
                className="mr-2 text-blue-500 flex-shrink-0"
              />
              <p className="flex-grow">
                Development of car and house(RealEstate) project.
              </p>
            </div>
            <div className="flex items-center my-2">
              <FaCheckCircle
                size={20}
                className="mr-2 text-blue-500 flex-shrink-0"
              />
              <p className="flex-grow">
                Participated in code reviews, providing constructive feedback
                and suggestions for improvement.
              </p>
            </div>
            <div className="flex items-center my-2">
              <FaCheckCircle
                size={20}
                className="mr-2 text-blue-500 flex-shrink-0"
              />
              <p className="flex-grow">
                Strong communication and interpersonal skills, working closely
                with team members and clients to achieve project goal.
              </p>
            </div>
          </motion.div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-1 mt-7 md:mt-7">
          <motion.div
            className="bg-white p-6 rounded-lg shadow-md border-t-4 border-pink-500"
            whileHover={{ scale: 1.02 }}
            transition={{ duration: 0.3 }}
          >
            <h3 className="text-xl font-semibold">Junior Engineer</h3>
            <p className="text-gray-600">AcePlus Solutions Company Limited</p>
            <p className="text-gray-500 mb-4">February 2018 - February 2019</p>
            <div className="flex items-center my-2">
              <FaCheckCircle
                size={20}
                className="mr-2 text-pink-500 flex-shrink-0"
              />
              <p className="flex-grow">
                Developed a web application for food order and dealt with
                merchant management system using Laravel frameworks.
              </p>
            </div>
            <div className="flex items-center my-2">
              <FaCheckCircle
                size={20}
                className="mr-2 text-pink-500 flex-shrink-0"
              />
              <p className="flex-grow">
                Implemented features on existing system (koolpon food order
                application).
              </p>
            </div>
            <div className="flex items-center my-2">
              <FaCheckCircle
                size={20}
                className="mr-2 text-pink-500 flex-shrink-0"
              />
              <p className="flex-grow">
                Responsible for application code designing.
              </p>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
}

export default Experience;
