import React from "react";
import { motion } from "framer-motion";
import {
  FaUser,
  FaUserCircle,
  FaBook,
  FaCalendarAlt,
  FaStar,
  FaFlag,
} from "react-icons/fa";

function About() {
  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = "/assets/resume/hnin_hnin_wai_profile.docx";
    link.download = "hnin_hnin_wai_profile.docx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="flex flex-col md:flex-row bg-white p-6 rounded-lg shadow-md">
      <div className="flex sm:justify-start lg:justify-center md:justify-center items-center relative group w-full sm:w-3/4 lg:w-1/2">
        <motion.img
          src="assets/images/shwe.jpg"
          alt="Profile"
          className="rounded-md transition-opacity duration-300 group-hover:opacity-30 w-full"
          whileHover={{ scale: 1.1 }}
          transition={{ duration: 0.3 }}
        />

        <div className="absolute inset-0 bg-blue-500 bg-opacity-75 opacity-0 group-hover:opacity-100 hover:rounded-md transition-opacity duration-300 flex flex-col justify-center items-center text-white p-4">
          <p className="text-3xl font-bold my-5">I'm Hnin Hnin Wai</p>
          <p className="text-center font-bold text-2xl">
            I love to write code and learn new things.
          </p>
          <p className="mt-4 text-center">
            I've been in the field over five years and specialize in developing
            mobile applications.
          </p>
        </div>
      </div>
      <div className="md:w-2/3 mt-4 md:mt-0 md:ml-6">
        <h1 className="text-3xl font-bold mb-4">
          Hello, I'm Mobile Application Developer
        </h1>
        <p className="text-gray-700 mb-2">
        I have around 6 years of experience in software development, specializing in both back-end and front-end features, and 3 years of experience as Mobile Application Developer.
        </p>
        <p className="text-gray-700 mb-2">
          My primary programming languages are Kotlin and Flutter.
        </p>
        <p className="text-gray-700 mb-2">
          I've worked on various projects, including web applications, mobile
          apps, and large-scale data systems.
        </p>
        <p className="text-gray-700 mb-2">
          I possess a solid understanding of requirement specifications and best
          practices across the software development lifecycle, including coding
          standards, code reviews, source control management, build processes,
          testing, maintenance, and operations.
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div className="flex items-center">
            <FaUser className="text-gray-500 mr-2" />
            <span>Hnin Hnin Wai</span>
          </div>
          <div className="flex items-center">
            <FaCalendarAlt className="text-gray-500 mr-2" />
            <span>05-07-1994</span>
          </div>
          <div className="flex items-center">
            <FaUserCircle className="text-gray-500 mr-2" />
            <span>Female</span>
          </div>
          <div className="flex items-center">
            <FaStar className="text-gray-500 mr-2" />
            <span>Single</span>
          </div>
          <div className="flex items-center">
            <FaBook className="text-gray-500 mr-2" />
            <span>Buddhist</span>
          </div>
          <div className="flex items-center">
            <FaFlag className="text-gray-500 mr-2" />
            <span>Myanmar</span>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row sm:items-center justify-between mt-6 space-y-4 sm:space-y-0 sm:space-x-4">
          <div className="flex space-x-4">
            <a
              href="https://www.facebook.com/shwewaremelia"
              className="text-white bg-blue-500 hover:bg-blue-700 rounded-full w-10 h-10 flex items-center justify-center"
            >
              <i className="fab fa-facebook-f"></i>
            </a>
            <a
              href="https://line.me/ti/p/1nEsp49XUB"
              className="text-white bg-green-500 hover:bg-green-700 rounded-full w-10 h-10 flex items-center justify-center"
            >
              <i className="fab fa-line"></i>
            </a>
            <a
              href="https://www.linkedin.com/in/hnin-hnin-wai-97447a185/"
              className="text-white bg-blue-500 hover:bg-blue-400 rounded-full w-10 h-10 flex items-center justify-center"
            >
              <i className="fab fa-linkedin-in"></i>
            </a>
            <a
              href="https://github.com/shwehnin"
              className="text-white bg-gray-700 hover:bg-black rounded-full w-10 h-10 flex items-center justify-center"
            >
              <i className="fab fa-github"></i>
            </a>
          </div>
          <div className="sm:mt-2 md:mt-0">
            <button
              className="flex items-center px-4 py-2 bg-blue-600 text-white rounded hover:bg-indigo-600"
              onClick={handleDownload}
            >
              <FaUser className="mr-2" /> Download Resume
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
