import React from "react";
import { motion } from "framer-motion";

const webSkills = [
  "JavaScript",
  "React Js",
  "Node Js (Express)",
  "PHP(Laravel)",
  "HTML5",
  "CSS3/Tailwind CSS",
];

const mobileSkills = ["Dart (Flutter)", "Kotlin"];

const databaseSkills = ["MySQL", "MongoDB", "SqFlite/Floor"];

const otherSkills = ["AWS", "Firebase", "Github/Gitlab"];

function Skills() {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <motion.h2
        className="text-3xl font-bold mb-4 text-center"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        Skills Overview
      </motion.h2>
      <p className="text-gray-700 mb-4">
        I have more than 5 years' experience building rich web and mobile
        applications for clients in Myanmar(Burmese). Below is a quick overview
        of my main technical skill sets and tools I use.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        <motion.div
          className="bg-indigo-200 p-4 rounded-lg shadow-lg"
          whileHover={{ scale: 1.05 }}
          transition={{ duration: 0.3 }}
        >
          <h3 className="text-2xl font-bold mb-4">Mobile</h3>
          <ul className="list-disc pl-5 space-y-2">
            {mobileSkills.map((skill, index) => (
              <li
                key={index}
                initial={{ x: -20, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ delay: index * 0.1 }}
              >
                {skill}
              </li>
            ))}
          </ul>
        </motion.div>
        <motion.div
          className="bg-indigo-200 p-4 rounded-lg shadow-lg"
          whileHover={{ scale: 1.05 }}
          transition={{ duration: 0.3 }}
        >
          <h3 className="text-2xl font-bold mb-4">Web</h3>
          <ul className="list-disc pl-5 space-y-2">
            {webSkills.map((skill, index) => (
              <motion.li
                key={index}
                initial={{ x: -20, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ delay: index * 0.1 }}
              >
                {skill}
              </motion.li>
            ))}
          </ul>
        </motion.div>
        <motion.div
          className="bg-indigo-200 p-4 rounded-lg shadow-lg"
          whileHover={{ scale: 1.05 }}
          transition={{ duration: 0.3 }}
        >
          <h3 className="text-2xl font-bold mb-4">Database</h3>
          <ul className="list-disc pl-5 space-y-2">
            {databaseSkills.map((skill, index) => (
              <motion.li
                key={index}
                initial={{ x: -20, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ delay: index * 0.1 }}
              >
                {skill}
              </motion.li>
            ))}
          </ul>
        </motion.div>
        <motion.div
          className="bg-indigo-200 p-4 rounded-lg shadow-lg"
          whileHover={{ scale: 1.05 }}
          transition={{ duration: 0.3 }}
        >
          <h3 className="text-2xl font-bold mb-4">Others</h3>
          <ul className="list-disc pl-5 space-y-2">
            {otherSkills.map((skill, index) => (
              <motion.li
                key={index}
                initial={{ x: -20, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ delay: index * 0.1 }}
              >
                {skill}
              </motion.li>
            ))}
          </ul>
        </motion.div>
      </div>
    </div>
  );
}

export default Skills;
