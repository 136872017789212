import React from "react";
import { motion } from "framer-motion";
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";

function Contact() {
  return (
    <motion.div
      className="bg-gray-100 p-5 rounded-lg shadow-md"
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >

      <h2 className="text-4xl font-bold mb-4 text-center">Get in Touch</h2>
      <p className="text-lg mb-6 text-center">
        If you have any questions about my resume, please contact me.
      </p>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
        <motion.div
          className="bg-white p-6 rounded-lg shadow-md flex items-center"
          whileHover={{ scale: 1.05 }}
          transition={{ duration: 0.3 }}
        >
          <div className="mr-4">
            <FaPhoneAlt className="w-10 h-10 text-green-400" />
          </div>
          <div>
            <p className="font-semibold">Phone</p>
            <a href="tel:+660618631476" className="text-sm sm:text-base">+66 0618631476</a>
          </div>
        </motion.div>
        <motion.div
          className="bg-white p-6 rounded-lg shadow-md flex items-center"
          whileHover={{ scale: 1.05 }}
          transition={{ duration: 0.3 }}
        >
          <div className="mr-4">
            <FaEnvelope className="w-10 h-10 text-red-400" />
          </div>
          <div className="flex-1">
            <p className="font-semibold text-base sm:text-lg">Email</p>
            <a href="mailto:shwehnindev@gmail.com" className="text-sm sm:text-base text-blue-500 max-w-full break-words">
              shwehnindev@gmail.com
            </a>
            <br />
            <a href="mailto:hninhninwai719@gmail.com" className="text-sm sm:text-base text-blue-500 max-w-full break-words">
              hninhninwai719@gmail.com
            </a>
          </div>
        </motion.div>
        <motion.div
          className="bg-white p-6 rounded-lg shadow-md flex items-center"
          whileHover={{ scale: 1.05 }}
          transition={{ duration: 0.3 }}
        >
          <div className="mr-4">
            <FaMapMarkerAlt className="w-10 h-10 text-indigo-400" />
          </div>
          <div>
            <p className="font-semibold text-base sm:text-lg">Address</p>
            <p className="text-sm sm:text-base">50, Hua Mak, Bang Kapi District, Bangkok 10240, Thailand</p>
          </div>
        </motion.div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-1 gap-6">
        <div className="bg-white p-6 rounded-lg shadow-md">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m24!1m12!1m3!1d3875.4219558612467!2d100.64073382509632!3d13.753408947256268!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m9!3e6!4m3!3m2!1d13.753475!2d100.6434196!4m3!3m2!1d13.7535271!2d100.6433552!5e0!3m2!1sen!2sth!4v1722481732208!5m2!1sen!2sth"
            width="100%"
            height="350"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="Location Map"
          />
        </div>
      </div>
    </motion.div>
  );
}

export default Contact;
