import React from "react";
import { motion } from "framer-motion";

function ProjectCard({
  name,
  description,
  imageUrl,
  tags,
  link,
  hoverColor = "blue-500", // Prop for hover color
}) {
  return (
    <div className="max-w-sm mx-auto">
      <div className="relative max-w-sm rounded-t-md overflow-hidden shadow-lg bg-white group" style={{ height: '185px' }}>
        <motion.img
          className="w-full h-48 object-contain"
          src={imageUrl}
          alt={name}
          whileHover={{ scale: 1.1 }}
          transition={{ duration: 0.3 }}
        />
        <motion.div
          className={`absolute inset-0 bg-${hoverColor} bg-opacity-75 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center justify-center text-white text-center p-4`}
          initial={{ opacity: 0 }}
          whileHover={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
        >
          <div>
            <h3 className="text-2xl font-bold mb-2">{name}</h3>
            <div className="flex flex-wrap justify-center space-x-2">
              {tags.map((tag, index) => (
                <span
                  key={index}
                  className="bg-gray-700 rounded-full px-3 py-1 mt-2 text-sm font-semibold text-white"
                >
                  {tag}
                </span>
              ))}
            </div>
          </div>
        </motion.div>
      </div>
      <div className="bg-indigo-500 rounded-b-md p-5 h-72 flex flex-col justify-between">
        <p className="text-gray-50">{description}</p>
        <div className="flex items-center justify-end mt-2">
          <a href={link} target="_blank" rel="noopener noreferrer"  className="p-2 rounded-md bg-gray-200">{name === "E-commerce Food Delivery" ? "View on Github" : "Download App"}</a>
        </div>
      </div>
    </div>
  );
}

export default ProjectCard;
