import React, { useState } from 'react';
import { Link } from 'react-scroll';
import { FaHome, FaBriefcase, FaTools, FaProjectDiagram, FaEnvelope, FaGraduationCap } from 'react-icons/fa';

function Sidebar({ closeSidebar }) {
  const [selectedItem, setSelectedItem] = useState('about');

  const menuItems = [
    { id: 'about', label: 'ABOUT', icon: FaHome },
    { id: 'experience', label: 'EXPERIENCE', icon: FaBriefcase },
    { id: 'skills', label: 'SKILLS', icon: FaTools },
    { id: 'project', label: 'PROJECTS', icon: FaProjectDiagram },
    { id: 'education', label: 'EDUCATION', icon: FaGraduationCap },
    { id: 'contact', label: 'CONTACT', icon: FaEnvelope },
  ];

  const handleMenuItemClick = (id) => {
    setSelectedItem(id);
    closeSidebar();
  };

  return (
    <aside className="w-72 bg-custom-primary text-white h-full flex-shrink-0">
      <div className="p-4 md:p-6">
        <img
          src="/assets/images/hh.jpg"
          alt="Profile"
          className="h-32 rounded-lg my-3 mx-auto object-contain"
        />
        <h1 className="text-2xl font-bold text-center mt-4">Hnin Hnin Wai</h1>
        <p className="text-gray-100 mt-2 font-bold text-center">shwehnindev@gmail.com</p>
        <nav className="mt-6">
          <ul className="space-y-4">
            {menuItems.map((item) => (
              <li
                key={item.id}
                className={`${
                  selectedItem === item.id
                    ? 'bg-custom-dark-primary'
                    : 'bg-custom-primary'
                } p-2 rounded-md flex items-center`}
              >
                <Link
                  to={item.id}
                  smooth={true}
                  duration={500}
                  className={`cursor-pointer px-2 rounded flex items-center ${
                    selectedItem === item.id
                      ? 'text-white'
                      : 'text-custom-light-blue'
                  }`}
                  onClick={() => handleMenuItemClick(item.id)}
                >
                  <item.icon className="mr-2" />
                  {item.label}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </aside>
  );
}

export default Sidebar;
